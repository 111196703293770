* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  --secondary: #ff815a;
  --header-height: 120px;
  --footer-height: 170px;
  --body: neue-haas-grotesk-display, sans-serif;
  --heading: ivypresto-display, serif;
}

#root {
  overflow-x: hidden;
}

button,
input[type="submit"] {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.accent {
  color: var(--secondary);
}

.loader {
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top: 5px solid var(--secondary);
  width: 30px !important;
  height: 30px !important;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animated-check {
  height: 30px;
  width: 30px;
}

.animated-check path {
  fill: none;
  stroke: var(--secondary);
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 0.6s cubic-bezier(0.16, 0.8, 0.54, 1.01) forwards;
  stroke-linecap: square;
  stroke-linejoin: miter;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1023px) {
  body {
    --header-height: 120px;
  }
}
